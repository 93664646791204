<!-- This is the file that will render any external pages you do not want inside the dash, like login form-->
<template>
  <v-app
    id="login-home"
    dark
  >
      <vue-particles
      color="#04b604"
      linesColor= "#65b20a"
      >
      </vue-particles>
    <transition>  
      <router-view/>
    </transition>    
  </v-app>
</template>

<script>
export default {};
//<v-app id="login-home" v-bind:style="{ backgroundColor: 'gray'}">
</script>

<style>
#particles-js { 
  /*position: absolute;
  background-size: cover;
  top: 0; 
  bottom: 0; 
  left: 0;
  right: 0; 
  overflow-y: hidden; 
  z-index: 0;*/
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}
</style>